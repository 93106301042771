import { ReactElement } from 'react';

import s from './style.module.css';

import enterIcon from '~/assets/images/enterIcon.png';
import backWhiteArrow from '@assets/images/back-white-arrow.png';
import forwardWhiteArrow from '@assets/images/forward-white-arrow.png';

interface Props {
  title: string;
  disabled?: boolean;
  nextLabel?: string;
  children: ReactElement | ReactElement[];
  onNext?: () => void;
  onPrev?: () => void;
}

export default function SlideContainer({
  title,
  disabled = false,
  nextLabel = 'Next',
  children,
  onNext,
  onPrev,
}: Props) {
  const hasNext = onNext !== undefined;
  const hasBack = onPrev !== undefined;

  return (
    <div className={s.slide}>
      <div className={s.slide__inner}>
        {!!title.length && <h2 className={s.slide__title}>{title}</h2>}

        <div className={s.slide__content}>{children}</div>

        <div className={s.slide__navigation}>
          <button
            tabIndex={-1}
            className={s.slide__navigation__btn}
            disabled={!hasBack || disabled}
            style={{ opacity: !hasBack ? 0 : disabled ? 0.5 : 1 }}
            onClick={onPrev}
          >
            <img alt="back" className={s.slide__navigation__arrowIcon} src={backWhiteArrow} />
            <div>Previous</div>
          </button>

          <button
            tabIndex={-1}
            className={s.slide__navigation__btn}
            style={{ opacity: disabled ? 0.5 : 1 }}
            disabled={!hasNext || disabled}
            onClick={onNext}
          >
            <>
              <div>{nextLabel}</div>

              <img className={s.slide__navigation__arrowIcon} src={forwardWhiteArrow} alt="" />
            </>
          </button>
        </div>

        <div className={s.slide__help}>
          <span>Press Enter</span>
          <img src={enterIcon} alt="" />
        </div>
      </div>
    </div>
  );
}
