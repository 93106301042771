import styled, { css } from 'styled-components';

import { gaps } from '~/ui/kit/constants/sizes';
import { accentFont, fontSize, getFontSize } from '~/ui/kit/constants/fonts';

import { Accordion } from '~/ui/kit/organisms/Accordion';
import { Popup } from '~/ui/kit/organisms/Popup';
import * as PopupComponents from '~/ui/kit/organisms/Popup/Popup.organism.styled';
import { Button } from '~/ui/kit/atoms/Button';

const itemMarginBottom = css`
  margin-bottom: ${gaps.xs}px;
`;

export const Container = styled(Popup).attrs({
  isLoading: false,
  disableControls: true,
  autoFocus: false,
  titleInHeader: true,
})`
  ${PopupComponents.HeaderContainer} {
    ${itemMarginBottom};
    justify-content: flex-start;
  }

  ${PopupComponents.BaseTitle} {
    color: var(--eko-primary-color);
    ${getFontSize('xl')};
    font-size: 24px;
    ${accentFont};
  }

  ${PopupComponents.ChildrenContent} {
    max-width: unset;
    overflow: auto;
    max-height: 60vh;
    max-height: calc(60vh - 20px);
  }
`;

export const MainTextsContainer = styled.ul.attrs({
  $fs: 's',
})`
  ${fontSize};
`;

export const ActionButton = styled(Button).attrs({ type: 'link' })`
  font-weight: bold;
`;

export const KnowledgeCenterButton = styled(ActionButton)``;

export const SelectableTexts = styled(Accordion)``;
