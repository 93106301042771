import * as CommonStyled from '../FundWithdrawPage.common.styled';

export const SkeletonComponent = () => {
  return (
    <CommonStyled.ContentContainer>
      <CommonStyled.Section>
        <CommonStyled.SkeletonItem />
      </CommonStyled.Section>
      <CommonStyled.SecondSkeletonContainer>
        <CommonStyled.SkeletonItem />
      </CommonStyled.SecondSkeletonContainer>
    </CommonStyled.ContentContainer>
  );
};

export const Skeleton = <SkeletonComponent />;
