import React from 'react';
import loadable from '@loadable/component';

import { Skeleton } from './PerformancePage.skeleton';

import { PageLayout } from '~/ui/layouts/Page';

const PerformanceContent = loadable(() => import('./PerformancePage'), { fallback: Skeleton });

export const PerformancePageWrapper = () => {
  return (
    <PageLayout title={null} showFundBtn={false}>
      <PerformanceContent />
    </PageLayout>
  );
};
