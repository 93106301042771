import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { useIsPortfolioFunded } from '~/shared/hooks';
import { selectProfile } from '@store/profile/selectors';
import { PORTFOLIO_TYPE, useActivePortfolioInfo, useRiskLevelLabel } from '~/entities/protfolio';
import { OnboardingStep } from '~/widgets/PageOnboarding';

import {
  getMixedPortfolioOnboarding,
  getPremadePortfolioOnboarding,
  getSelfDirectedPortfolioOnboarding,
  convertToHashMap,
} from './configs';

type OnboardingStepsResult = {
  steps: OnboardingStep[];
  stepsHashMap: Record<string, OnboardingStep>;
};

export const usePortfolioOnboardingStepsHook = (enabled: boolean, disableScrollWidth: number) => {
  const riskLevelLabel = useRiskLevelLabel();
  const profileData = useSelector(selectProfile);
  const [activePortfolioInfo] = useActivePortfolioInfo();

  const activePortfolioType = useMemo(
    () =>
      pipe(
        activePortfolioInfo,
        RD.map(({ portfolioType }) => portfolioType),
        RD.toUndefined,
      ),
    [activePortfolioInfo],
  );

  const isPortfolioFunded = useIsPortfolioFunded();

  const userTopics = useMemo(() => profileData?.topics || [], [profileData?.topics]);

  return useMemo((): OnboardingStepsResult => {
    let stepsByPortfolioType: OnboardingStep[] = [];

    switch (activePortfolioType) {
      case PORTFOLIO_TYPE.PRE_MADE: {
        stepsByPortfolioType = getPremadePortfolioOnboarding(disableScrollWidth);
        break;
      }
      case PORTFOLIO_TYPE.MIXED: {
        stepsByPortfolioType = getMixedPortfolioOnboarding(disableScrollWidth, riskLevelLabel, userTopics);
        break;
      }
      case PORTFOLIO_TYPE.SELF_DIRECT: {
        stepsByPortfolioType = getSelfDirectedPortfolioOnboarding(disableScrollWidth, !isPortfolioFunded);
        break;
      }
    }

    const stepsHashMap = convertToHashMap(stepsByPortfolioType);

    return {
      steps: stepsByPortfolioType,
      stepsHashMap,
    };
  }, [riskLevelLabel, userTopics, activePortfolioType, isPortfolioFunded, disableScrollWidth]);
};
