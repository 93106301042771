import { ContentContainer, StatsContainer } from './PortfolioPage.styled';
import * as Skeletons from './Portfolio.page.skeleton.styled';

export const PortfolioPageSkeleton = () => {
  return (
    <ContentContainer>
      <StatsContainer>
        <Skeletons.ValueSkeleton />
        <Skeletons.BreakdownSkeleton />
      </StatsContainer>
      <Skeletons.HoldingsSkeleton />
    </ContentContainer>
  );
};

export const Skeleton = <PortfolioPageSkeleton />;
