import { getOnboardingStepCNFactory } from '~/shared/onboardings';
import { OnboardingStep } from '~/widgets/PageOnboarding';

export const getStepCN = getOnboardingStepCNFactory('portfolio');

export const convertToHashMap = (steps: OnboardingStep[]) =>
  steps.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }),
    {} as Record<string, OnboardingStep>,
  );
