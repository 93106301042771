import styled, { css } from 'styled-components';

import { media } from '~/ui/utils/media';
import { Skeleton as UISkeleton } from '~/ui/kit/atoms/Skeleton';

const SectionSkeleton = styled(UISkeleton.Block)<{ $isActiveInvestor?: boolean }>`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Finances = styled(SectionSkeleton)`
  ${({ $isActiveInvestor }) =>
    $isActiveInvestor &&
    css`
      min-height: 451px;

      ${media.md`
      min-height: 499px;
    `}

      ${media.xl`
      min-height: 536.945px;
    `}
    `};

  ${({ $isActiveInvestor }) =>
    !$isActiveInvestor &&
    css`
      min-height: 451px;

      ${media.md`
      min-height: 499px;
    `}

      ${media.xl`
      min-height: 536.945px;
    `}
    `};
`;

export const FinProjection = styled(SectionSkeleton)`
  min-height: 473px;

  ${media.xs`
    min-height: 452px;
  `};

  ${media.sm`
    min-height: 453.4px;
  `};

  ${media.md`
    min-height: 389px;
  `};

  ${media.lg`
    min-height: 365px;
  `};
`;

export const InvestorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${media.xl`
    flex-direction: row;
  `};
`;

export const Holdings = styled(UISkeleton.Block)`
  min-height: 243px;

  ${media.sm`
    min-height: 252px;
  `};

  ${media.xl`
    min-height: 310px;
    flex: 60% 1 0;
  `};
`;

export const Highlighted = styled(UISkeleton.Block)`
  min-height: 280px;
`;
