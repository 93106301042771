import React from 'react';
import { OnboardingStep } from '~/widgets/PageOnboarding';

import { getStepCN } from './portfolio.onboarding.helpers';

export const getSelfDirectedPortfolioOnboarding = (
  disableScrollWidth: number,
  isPortfolioEmpty: boolean,
): OnboardingStep[] => [
  {
    id: 'wallet',
    className: getStepCN('wallet'),
    position: 'bottom' as const,
    content: isPortfolioEmpty ? (
      <>Before you can buy stocks or ETFs, you first need to fund your wallet.</>
    ) : (
      <>Use your wallet to buy stocks and ETFs</>
    ),
  },
  {
    id: 'holdings',
    className: getStepCN('holdings'),
    position: 'top-middle-aligned' as const,
    disableScrollWidth,
    content: (
      <>
        These are the individual holdings that make up your portfolio.{' '}
        {isPortfolioEmpty ? (
          <>Currently you don’t have any holdings yet.</>
        ) : (
          <>These holdings you have bought yourself.</>
        )}
      </>
    ),
  },
  {
    id: 'addHoldings',
    className: getStepCN('addHoldings'),
    position: 'bottom-right-aligned' as const,
    disableScrollWidth,
    fillDefaultBackground: true,
    highlightBorderRadius: 'm' as const,
    content: <>To buy a stock or learn more about it, simply search and select here.</>,
  },
  {
    id: 'equityValue',
    className: getStepCN('equityValue'),
    position: 'bottom-left-aligned' as const,
    content: <>The value of all your portfolio holdings is displayed here.</>,
  },
  {
    id: 'breakdown',
    className: getStepCN('breakdown'),
    position: 'bottom' as const,
    content: <>This is the composition of our portfolio, split in stocks, bond-ETFs and ETFs</>,
  },
  {
    id: 'info',
    className: getStepCN('info'),
    position: 'bottom-right-aligned' as const,
    disableScrollWidth,
    fillDefaultBackground: true,
    highlightBorderRadius: 'xl' as const,
    content: <>To learn more about your portfolio, click the info button.</>,
  },
];
