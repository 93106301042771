import React, { FC, useContext, useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import * as company from '~/services/company';
import { PORTFOLIO_TYPE, useActivePortfolioInfo, useRiskLevelLabel } from '~/entities/protfolio';
import { Props as AccordionProps } from '~/ui/kit/organisms/Accordion';

import { useAnnualFee } from '@utils/useAnnualFee';
import * as routes from '@routes/names';

import { configs, InfoPopupMainItem, InfoPopupDropdownItem } from './configs';
import * as S from './InfoPopup.styled';

type Props = {
  isVisible: boolean;
  className?: string;
  onClose: VoidFunction;
  onOnboardingClick: VoidFunction;
};

export const InfoPopupWidget: FC<Props> = (props) => {
  const companySettings = useContext(company.CompanySettingsContext);
  const minimumDepositAmount = company.getSettingsKey(companySettings, company.DEPOSIT_MINIMUM_KEY);
  const riskLevelLabel = useRiskLevelLabel();
  const annualFee = useAnnualFee();
  const [activePortfolioInfo] = useActivePortfolioInfo();

  const mainTexts = useMemo(() => {
    return pipe(
      activePortfolioInfo,
      RD.map(({ portfolioType, name, infoBullets }) => {
        switch (portfolioType) {
          case PORTFOLIO_TYPE.PRE_MADE:
            return infoBullets.length > 0 ? infoBullets : configs.premade.getMainTexts(name);
          case PORTFOLIO_TYPE.MIXED:
            return configs.mixed.getMainTexts(riskLevelLabel);
          case PORTFOLIO_TYPE.SELF_DIRECT:
            return configs.selfDirected.getMainTexts();
        }
      }),
      RD.getOrElse((): InfoPopupMainItem[] => []),
    );
  }, [riskLevelLabel, activePortfolioInfo]);

  const items: AccordionProps['items'] = useMemo(() => {
    return pipe(
      activePortfolioInfo,
      RD.map(({ portfolioType }) => {
        switch (portfolioType) {
          case PORTFOLIO_TYPE.PRE_MADE:
            return configs.premade.getDropdownTexts(annualFee, minimumDepositAmount);
          case PORTFOLIO_TYPE.MIXED:
            return configs.mixed.getDropdownTexts(annualFee);
          case PORTFOLIO_TYPE.SELF_DIRECT:
            return configs.selfDirected.getDropdownTexts();
        }
      }),
      RD.getOrElse((): InfoPopupDropdownItem[] => []),
      (items) =>
        items.map((item) => ({
          key: item.key,
          label: item.title,
          children: item.text,
        })),
    );
  }, [annualFee, activePortfolioInfo, minimumDepositAmount]);

  return (
    <S.Container
      className={props.className}
      isVisible={props.isVisible}
      title="Your portfolio"
      onClickNo={props.onClose}
      description={
        <>
          If you have more questions, check out our{' '}
          <S.KnowledgeCenterButton link={routes.FAQ}>Knowledge center</S.KnowledgeCenterButton>
        </>
      }
    >
      <S.MainTextsContainer>
        {mainTexts.map((text) => (
          <li key={text}>{text}</li>
        ))}
        <li>
          Click <S.ActionButton onClick={props.onOnboardingClick}>here</S.ActionButton> to take the portfolio tour again
        </li>
      </S.MainTextsContainer>
      <S.SelectableTexts items={items} />
    </S.Container>
  );
};
