import styled from 'styled-components';
import { Skeleton as UISkeleton } from '~/ui/kit/atoms/Skeleton';
export { HoldingsSkeleton } from './widgets/Holdings/HoldingsSkeleton.styled';

export const ValueSkeleton = styled(UISkeleton.Block)`
  min-height: 68px;
`;

export const BreakdownSkeleton = styled(UISkeleton.Block)`
  min-height: 150px;
`;
