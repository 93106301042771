import { InfoPopupMainItem, InfoPopupDropdownItem } from './InfoPopup.configs.types';

export const getMainTexts = (): InfoPopupMainItem[] => [
  'In the portfolio view, you can see all stock and ETFs that you own.',
  'Any buy or sell action you initiate will be executed instantly during stock market hours.',
];

export const getDropdownTexts = (): InfoPopupDropdownItem[] => [
  {
    key: 2,
    title: 'What are the costs?',
    text: `Investing is commission-free. We don’t charge anything per trade, or to open or maintain your account.`,
  },
  {
    key: 3,
    title: 'What types of investments can I make on the self-directed investment platform?',
    text: `You have access to a wide range of investment options, including more than 5,000 fractionable stocks and 
            ETFs. You can buy and sell securities directly through the platform, as often as you like.`,
  },
  {
    key: 4,
    title: 'Is there a minimum investment amount for buying/selling equities?',
    text: `There is a minimum investment amount of $1.`,
  },
  {
    key: 5,
    title: 'How do I get started with investing?',
    text: `In order to get started with your self-directed portfolio, you first need to fund your ‘Wallet’. 
           To do so, simply go to the ‘Fund / Withdraw’ tab and pick an amount. After your wallet is funded, 
           you can search stocks and ETFs in the ‘Portfolio’ tab, and simply buy them with the click of a button.`,
  },
];
