enum SlideType {
  'LegalName' = 'LegalName',
  'DateOfBirth' = 'DateOfBirth',
  'TaxResidency' = 'TaxResidency',
  'USCitizen' = 'USCitizen',
  'SSN' = 'SSN',
  'CCB' = 'CCB',
  'VisaDetails' = 'VisaDetails',
  'DateOfDeparture' = 'DateOfDeparture',
  'ContactInfo' = 'ContactInfo',
  'EmploymentStatus' = 'EmploymentStatus',
  'EmployerInfo' = 'EmployerInfo',
  'FinancialSituation' = 'FinancialSituation',
  'Disclosures' = 'Disclosures',
  'PoliticallyExposed' = 'PoliticallyExposed',
  'Confirmation' = 'Confirmation',
  'NotSupported' = 'NotSupported',
}

export default SlideType;
