import { createRef, KeyboardEventHandler, useCallback, useMemo, useState } from 'react';

type BuildPressParams = {
  onEnter?: null | VoidFunction;
  onTab?: null | VoidFunction;
};

export const buildPress = ({ onEnter = null, onTab = null }: BuildPressParams): KeyboardEventHandler => {
  return (e) => {
    if (e.key === 'Enter' && onEnter !== null) {
      e.preventDefault();
      onEnter();
      return;
    }

    if (e.key === 'Tab' && onTab !== null) {
      e.preventDefault();
      onTab();
    }
  };
};

// todo revise IMPORTANT
export const useReferences = <T extends HTMLElement = HTMLInputElement>(references: number) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const refs = useMemo(() => new Array(references).fill(null).map(() => createRef<T>()), [references]);

  const onTab = useCallback(() => {
    if (currentIndex === refs.length - 1) {
      refs[0].current?.focus();
      setCurrentIndex(0);
    } else {
      refs[currentIndex + 1].current?.focus();
      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, refs]);

  const buildFocus = useCallback(
    (index: number) => () => {
      setCurrentIndex(index);
    },
    [],
  );

  return useMemo(
    () => ({
      refs,
      onTab,
      buildFocus,
    }),
    [refs, onTab, buildFocus],
  );
};
