import { useRef } from 'react';
import { constVoid } from 'fp-ts/function';

import Form from './Form';
import SlideContainer from '../base/SlideContainer';

import Slide, { Handlable } from '../base/Slide';
import { Fields } from '../visa_details/context';
import SlideType from '../types';
import VisaDetailsContext from './context';

import { Validable } from '../base/validators';
import { validate } from '../visa_details/validators';

interface Props extends Handlable {
  [Fields.VISA_TYPE]: string;
  [Fields.VISA_EXPIRATION_DATE]: string;
  title: string;
  onChange: (field: keyof VisaDetailsContext, val: VisaDetailsContext[keyof VisaDetailsContext]) => void;
}

export const VisaDetailsSlideComponent = (props: Props) => {
  const ref = useRef<Validable>(null);

  const next = async () => {
    if (await ref.current?.validate()) {
      void (props.onNext && props.onNext());
    }
  };

  return (
    <SlideContainer title={props.title} onNext={next} onPrev={props.onPrev}>
      <Form
        onComplete={constVoid}
        ref={ref}
        visaType={props[Fields.VISA_TYPE]}
        visaExpiration={props[Fields.VISA_EXPIRATION_DATE]}
        onChange={props.onChange}
      />
    </SlideContainer>
  );
};

VisaDetailsSlideComponent.defaultProps = {
  title: 'Please provide your visa details',
};

type RenderParams = Parameters<Slide<VisaDetailsContext>['render']>[0];

export default class VisaDetailsSlide extends Slide<VisaDetailsContext> {
  protected _type: SlideType = SlideType.VisaDetails;

  public async isValid(ctx: VisaDetailsContext): Promise<boolean> {
    return validate(ctx[Fields.VISA_TYPE], ctx[Fields.VISA_EXPIRATION_DATE]);
  }

  public render({ ctx, onChange, onNext, onPrev }: RenderParams) {
    return (
      <VisaDetailsSlideComponent
        visaType={ctx[Fields.VISA_TYPE]}
        visaExpirationDate={ctx[Fields.VISA_EXPIRATION_DATE]}
        onChange={onChange}
        onNext={onNext}
        onPrev={onPrev}
      />
    );
  }
}
