import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';

import { selectProfile } from '@store/profile/selectors';
import { PageLayout } from '~/ui/layouts/Page';

import { Skeleton } from './components/Skeleton';

type Props = {
  className?: string;
};

const FundWithdrawPageContent = loadable(() => import('./FundWithdrawPage'), { fallback: Skeleton });

export const FundWithdrawPageWrapper: React.FC<Props> = (props) => {
  const profile = useSelector(selectProfile);
  return (
    <PageLayout className={props.className} title="Fund / Withdraw" showFundBtn={!profile?.has_alpaca_account}>
      <FundWithdrawPageContent />
    </PageLayout>
  );
};
