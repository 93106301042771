import styled from 'styled-components';

import { Button } from '~/ui/kit/atoms/Button';
import { IconInfo } from '~/ui/kit/atoms/IconInfo';
import { InfoTooltip } from '~/ui/kit/molecules/InfoTooltip';

export const Container = styled(InfoTooltip).attrs({})`
  display: flex;
  padding: 0;
  height: 24px;
  width: 24px;
`;

export const ActionButton = styled(Button).attrs({ type: 'ghost' })`
  padding: 0;

  &.ant-btn {
    color: inherit;
    height: 100%;
    display: flex;
  }
`;

export const Icon = styled(IconInfo)`
  width: 100%;
  height: 100%;
`;
