import { useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { PORTFOLIO_TYPE, useActivePortfolioInfo } from '~/entities/protfolio';

const PREMADE_CONTENT = {
  title: 'Welcome to your Pre-Made portfolio!',
  content: `This is a diversified portfolio with a variety of holdings from different asset classes, to ensure optimal 
            performance.`,
};

const MIXED_CONTENT = {
  title: 'Welcome to your Hybrid portfolio!',
  content: 'This portfolio is 100% personalized, in line with your risk tolerance, investment horizon and preferences.',
};

const SELF_DIRECTED_CONTENT = {
  title: 'Welcome to your self-directed portfolio!',
  content:
    'Your portfolio is now empty. Click below to see how your portfolio works, and how to buy your first stock or ETF.',
};

export const useGetWelcomePopupContent = () => {
  const [activePortfolioInfo] = useActivePortfolioInfo();

  return useMemo(
    () =>
      pipe(
        activePortfolioInfo,
        RD.map(({ portfolioType }) => {
          switch (portfolioType) {
            case PORTFOLIO_TYPE.PRE_MADE:
              return PREMADE_CONTENT;
            case PORTFOLIO_TYPE.MIXED:
              return MIXED_CONTENT;
            case PORTFOLIO_TYPE.SELF_DIRECT:
              return SELF_DIRECTED_CONTENT;
          }
        }),
        RD.toUndefined,
      ),
    [activePortfolioInfo],
  );
};
