import * as premade from './getPremadeInfoTexts.config';
import * as mixed from './getMixedInfoTexts.config';
import * as selfDirected from './getSelfDirectedInfoTexts.config';

export const configs = {
  premade,
  mixed,
  selfDirected,
};

export * from './InfoPopup.configs.types';
