import { some, values } from 'lodash';

export const requiredText = (val: string | null) => (!val || val?.length === 0 ? 'Required' : '');

export const hasError = (error: string): boolean => error?.length > 0;

export const hasErrors = (errors: Record<string, any>): boolean =>
  some(values(errors), (e) => {
    if ((e && Array.isArray(e)) || (typeof e === 'string' && e.length)) {
      return Boolean(e.length);
    }

    return false;
  });
