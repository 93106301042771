import React from 'react';
import { OnboardingStep } from '~/widgets/PageOnboarding';

import { getStepCN } from './portfolio.onboarding.helpers';

export const getPremadePortfolioOnboarding = (disableScrollWidth: number): OnboardingStep[] => [
  {
    id: 'breakdown',
    className: getStepCN('breakdown'),
    position: 'bottom' as const,
    content: (
      <>
        Your portfolio consists of reputable Stocks and Bond-ETFs, and is designed to yield an average annual return of
        6-8%.
      </>
    ),
  },
  {
    id: 'holdings',
    className: getStepCN('holdings'),
    position: 'top-middle-aligned' as const,
    disableScrollWidth,
    content: (
      <>
        These are the individual holdings that make up your portfolio. Your holdings contain broad exposure to different
        asset classes in order to reducing risk through diversification.
      </>
    ),
  },
  {
    id: 'equityValue',
    className: getStepCN('equityValue'),
    position: 'bottom-left-aligned' as const,
    content: (
      <>When you fund your portfolio, all your holdings will be bought in line with their given allocations (%).</>
    ),
  },
  {
    id: 'info',
    className: getStepCN('info'),
    position: 'bottom-right-aligned' as const,
    disableScrollWidth,
    fillDefaultBackground: true,
    highlightBorderRadius: 'xl' as const,
    content: (
      <>To learn more about your portfolio, including how we selected your holdings and costs, click the info button.</>
    ),
  },
];
