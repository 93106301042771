import styled from 'styled-components';

import { gaps } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

import { Skeleton as UISkeleton } from '~/ui/kit/atoms/Skeleton';

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${gaps.xl}px;

  ${media.lg`
    flex-direction: row;
    gap: ${gaps.xxl}px;
  `}
`;

export const Section = styled.section`
  width: 100%;

  ${media.lg`
    max-width: 50%;
    
   &:only-child {
    max-width: calc(50% - ${gaps.xxl / 2}px);
   }
  `}
`;

export const SecondSkeletonContainer = styled(Section)`
  display: none;

  ${media.lg`
    display: block;
  `};
`;

export const SkeletonItem = styled(UISkeleton.Block)`
  min-height: 500px;
`;
