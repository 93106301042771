import React, { useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { FirstDepositStatus, useActivePortfolioInfo } from '~/entities/protfolio';

import * as Skeletons from './PerformancePage.skeleton.styled';

const PerformancePageSkeleton = () => {
  const [activePortfolioInfo] = useActivePortfolioInfo();

  const isActiveInvestor = useMemo(
    () =>
      pipe(
        activePortfolioInfo,
        RD.map((s) => s.firstDepositStatus === FirstDepositStatus.PROCESSED),
        RD.getOrElse(() => false),
      ),
    [activePortfolioInfo],
  );

  return (
    <>
      <Skeletons.Finances $isActiveInvestor={isActiveInvestor} />
      {isActiveInvestor ? (
        <Skeletons.InvestorContent>
          <Skeletons.Holdings />
          <Skeletons.Highlighted />
        </Skeletons.InvestorContent>
      ) : (
        <Skeletons.FinProjection />
      )}
    </>
  );
};

export const Skeleton = <PerformancePageSkeleton />;
export { FinProjection as FinProjectionSkeleton } from './PerformancePage.skeleton.styled';
